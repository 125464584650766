import React, { useState } from 'react';
import axios from 'axios';
import { Link, router } from '@inertiajs/react'
import { useTranslation } from 'react-i18next';
import GenericLayout from '@/components/layout/GenericLayout';
import AuthBackground from '@/components/layout/AuthBackground';
import { getuserApiServiceInstance } from "@/lib/api/UserApiService";
import useIsMobile from '@/lib/hooks/useIsMobile';
import { sendToast } from '@/lib/utils/sendToast';
import { Button } from '@/components/elements/UIElements/Button';
import { Input } from "@/components/input/Input";
import useUiState from "@/lib/hooks/useUiState";
import { Spinner } from "@/icons/loader/Spinner";
import * as Sentry from '@sentry/react';

const LoginForm = () => {
  const { t } = useTranslation(['translation', 'messages'])
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isMobile } = useIsMobile()
  const { uiState, setUiState } = useUiState({
    loading: false
  });

  const handleLogin = async (e) => {
    if (e) {
      e.preventDefault()
    }
    if (uiState.loading) return;
    setUiState({
      loading: true
    })
    try {
      const userApiService = getuserApiServiceInstance();
      const response = await userApiService.login({ email, password })
      router.visit(response.redirectUrl);
    }
    catch (error) {
      setUiState({
        loading: false,
        error: true,
        errorMsg: error?.message ?? ""
      })
    }

  };

  return (
    <AuthBackground


      mobileSubmitButton={
        uiState.loading
          ?
          <>
            <Button
              value={<Spinner />}
              size="smallLong"
              className="w-full"
            />
            <div className='text-xs pt-4 text-center' dangerouslySetInnerHTML={{ __html: t("LoginInfoMessage", { ns: "translation" }) }} />
          </>

          :
          <>
            <Button
              onClick={handleLogin}
              value={t("Login")}
              size="smallLong"
              className="w-full"
            />
            <div className='text-xs pt-4 text-center' dangerouslySetInnerHTML={{ __html: t("LoginInfoMessage", { ns: "translation" }) }} />
          </>

      }

      modalContent={
        <div className='w-full flex flex-col justify-between items-end gap-[32px]'>
          <span className='w-full text-2xl lg:text-[30px] font-semibold pb-5 lg:pb-0 tracking-[-0.6px]'>{t("Login Form Title", { ns: "translation" })}</span>
          {
            uiState.error && uiState.errorMsg.length > 0
              ?
              <div className='w-full unmask-me text-[14px] mb-[-16px] px-[12px] py-[8px] bg-destructive text-primaryforeground rounded-[12px]'>
                {uiState.errorMsg}
              </div>
              :
              <></>
          }
          <form onSubmit={handleLogin} className='flex unmask-me flex-col gap-[32px] w-full'>
            <div className='flex flex-col gap-[6px] -full  text-sm'>
              <label className='font-medium'>{t("Email")}</label>
              <Input
                name='email'
                // type="email"
                value={email}
                onChange={(e) => setEmail(e)}
                className='w-full rounded-xl border-border'
              />
            </div>
            <div className='flex flex-col gap-[32px] text-sm'>
              <div className='flex flex-col gap-[6px]'>
                <div className='flex flex-col gap-[6px]'>
                  <label className='font-medium'>{t("Password")}</label>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e)}
                    className='rounded-xl border-border pb-4'
                  />
                </div>
                <Link
                  href={route('reset-password')}
                  className="w-fit mt-[6px] lg:pt-0 underline text-sm text-foreground rounded-md focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t("Have you forgot password")} ?
                </Link>
              </div>
              {
                !isMobile
                  ?
                  <>
                    {
                      uiState.loading
                        ?
                        <Button
                          value={<Spinner />}
                          size="smallLong"
                        />
                        :
                        <Button
                          value={t("Login")}
                          size="smallLong"
                        />

                    }
                  </>


                  :
                  <></>
              }
            </div>
            {!isMobile && <div className='text-xs text-center' dangerouslySetInnerHTML={{ __html: t("LoginInfoMessage", { ns: "translation" }) }} />
}
          </form>
        </div >

      }
    />

  );
}

LoginForm.layout = page => (
  <GenericLayout children={page} />
)
export default LoginForm;

